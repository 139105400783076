import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as THREE from 'three';
import dataCache from './dataCache.js'; 
import JSZip from 'jszip';
import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';

export default function BlockModels({ name, url , dx ,dy ,dz , size  , grade ,bmvisible}) {
  const instancedMeshRef = useRef();
  const [ogdata, setOGData] = useState([]);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const temp = new THREE.Object3D();
  const color = new THREE.Color();
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [tooltip, setTooltip] = useState({ show: false, content: '', x: 0, y: 0 , z:0 });
  const [interval,setInterval] = useState([0,20])
  
  const [instancesData, setInstancesData] = useState([]);

  const fetchDataWithProgress = async (url) => {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get('Content-Length');

    let receivedLength = 0;
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;

      console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
    }

    return new Blob(chunks);
  };

  // Load data when component mounts
  useEffect(() => {
    if(!bmvisible) return
    console.log('model search');
      const fetchData = async () => {
        if (dataCache[url]) {
          console.log('local data found');
          setOGData(dataCache[url])
        }else{
          const userConfirmed = window.confirm(`Block model is not available on your cache , do you want to download it @ ${size}?`);
          if (userConfirmed) {
            console.log('downloading data')

            //////////////
            try {
              const blob = await fetchDataWithProgress(url);
              console.log('Download complete. Extracting...');
              const zip = await JSZip.loadAsync(blob);
              const fileName = Object.keys(zip.files)[0]; // Assuming there's only one file in the zip
              const fileData = await zip.file(fileName).async('string');
              const parsedData = d3.csvParse(fileData);
          
              setOGData(parsedData);
              dataCache[url] = parsedData; 
              
            } catch (error) {
              console.error('Error loading the zipped CSV file:', error);
            }
            
          }else{
            //set({ bmvisible: false  });
          }
        
        }
      }

    fetchData()
    
  }, [url,bmvisible]); // Only load data when URL changes

  useEffect(()=>{
    
    let tempArr = [];
    for (let i = ogdata.length - 1; i >= 0; i--) {
      // get the current object
      // check if the object's grade is within the range
      if (ogdata[i][grade] >= interval[0] && ogdata[i][grade]  <= interval[1]) {
        tempArr.push(ogdata[i])
      }
    }
    setData(tempArr)
    setCount(tempArr.length); 
  },[ogdata,interval])

  // Memoize colors calculation
  const colors = useMemo(() => {
    if (data.length === 0) return new Float32Array();
    const colorsArray = new Float32Array(data.length * 3);
    for (let i = 0; i < data.length; i++) {
      const value = parseFloat(data[i][grade]);
      if (value >= 3) {
        color.set(0xac02b4);
      } else if (value >= 1.5) {
        color.set(0xff0000);
      } else if (value >= 0.6) {
        color.set(0x00ff00);
      } else {
        color.set(0x0000ff);
      }
      colorsArray[i * 3] = color.r;
      colorsArray[i * 3 + 1] = color.g;
      colorsArray[i * 3 + 2] = color.b;
    }
    return colorsArray;
  }, [data,bmvisible]);

  useEffect(() => {
    console.log('visibility toggled')
    
    let idata = [];
    if (data.length > 0 && instancedMeshRef.current) {
      for (let i = 0; i < data.length; i++) {
        const x = data[i].XC - 205200 + dx;
        const y = data[i].ZC - 1180 +dy;
        const z = 7975000 - data[i].YC +dz;
        temp.position.set(x, y, z);
        temp.updateMatrix();
        idata.push(data)
        instancedMeshRef.current.setMatrixAt(i, temp.matrix);
      }
      setInstancesData(idata);
      // Update the instance
      instancedMeshRef.current.instanceMatrix.needsUpdate = true;
    }
  }, [data,bmvisible]);
  
  return bmvisible ? (
    <group>
    <instancedMesh frustumCulled={true} ref={instancedMeshRef} args={[null, null, count]} name={name} userData={{ instancesData }}  >
        <boxGeometry args={[0.9, 0.9, 0.9]}>
        <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
      </boxGeometry>
      <meshLambertMaterial vertexColors toneMapped={false} />
    </instancedMesh>
      
  </group>

  ) : null; 

}
