export const ModelList = [
    {
        'group':'Giant',
        'name':'Drone',
        'models':
        [
            {name:'Drone',url:'./models/drone.glb'},
            {name:'Topo',url:'./models/topo.glb'}
        ]   

    },
    {
        'group':'Giant',
        'name':'Workings',
        'models':
        [
            {name:'UG_Workings',url:'./models/ug_workings.glb'},
            {name:'All_Shafts',url:'./models/all_shafts.glb'},
            {name:'Makorokoza',url:'./models/makorokoza_shafts.glb'},
            ]   

    },
    {
        'group':'Giant',
        'name':'Geology',
        'models':
        [
            
            {name:'Orebody_HG',url:'./models/orebody_hg.glb'},
        ]   

    },
    {
        'group':'Holes',
        'name':'Holes',
        'models':
        [
            {type:'holes',name:'HIST_DD',url:'./files/Holes.zip' , dataset:'Giant_Hist_Surface', holetype:'DD'},
            {type:'holes',name:'HIST_RC',url:'./files/Holes.zip' , dataset:'Giant_Hist_Surface', holetype:'RC'},
            {type:'holes',name:'RES_RC',url:'./files/Holes.zip' , dataset:'Giant_RES_Surface', holetype:'RC'},
            {type:'holes',name:'HIST_AUG',url:'./files/Holes.zip' , dataset:'Giant_Hist_Surface', holetype:'AUG'},
            {type:'holes',name:'HIST_RAB',url:'./files/Holes.zip' , dataset:'Giant_Hist_Surface', holetype:'RAB'},
            ]   

    }
    
]