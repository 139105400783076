import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import Scribble from '../../Utils/Scribble.js';
import SceneComponent from './SceneComponent';
import { ModelList } from "../../Utils/ModelsList.js"
import LayerControl from './LayerControl'; // Import LayerControl component
import Measurements from '../../clickTools/Measurements.js';
import Info from '../../clickTools/Info.js';
import ColorChange from '../../clickTools/ColorChange.js';
import ObjectDetails from '../../clickTools/ObjectDetails.js';

const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isScribbleMode, setIsScribbleMode] = useState(false);
  const [selectedColor, setSelectedColor] = useState('black');
  const [previousSlideWasScene, setPreviousSlideWasScene] = useState(false);
  const [showDescription, setShowDescription] = useState(true); // Track description visibility

  // Automatically close sidebar on medium screens and below
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
        setIsSidebarOpen(false);
      }
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(()=>{
    console.log('presentation refreshed')
  },
    [])


  // Define the slides, with some containing scene-specific content
  const slides = [
    { type: 'image', heading: '3DMA', content: './presentation/3DMA.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA1.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA2.jpg' },
    { type: 'image', heading: '3DMA', content: './presentation/3DMA3.jpg' },
    { type: 'image', heading: 'GIANT MINE', content: './presentation/giant1.jpg' },
    {
      type: 'scene',
      heading: 'Old Workings',
      content: {
        visibleObjects: ['UG_Workings','All_Shafts'],
        cameraPosition: [199291.0419564719, 1274.8597889083126,-8004173.893210932],
        lookAt: [198168.30897941053, 952.1289562720644,-8004409.822362194],
        description:`• </strong> All UG Workings: Historic <strong> 
                    • </strong> All Shafts: 3 meters<strong> 
                   `
      }
    },
    {
      type: 'scene',
      heading: 'Topo',
      content: {
        visibleObjects: ['Topo','UG_Workings','All_Shafts'],
        cameraPosition: [199799, 2254,-8003913],
        lookAt: [198631, 1216,-8004233],
        description:`• </strong> Topo: Topography <strong> 
                   `
      }
    },
    {
      type: 'scene',
      heading: 'Makorokoza',
      content: {
        visibleObjects: ['Topo','Makorokoza','UG_Workings'],
        cameraPosition: [198700, 1126,-8004252],
        lookAt: [198631, 1126,-8004260],
        description:`• </strong> Makorkoza workings: The current makorokoza workings.<br>`
      }
    },
    {
      type: 'scene',
      heading: 'Drilling',
      content: {
        visibleObjects: ['HIST_DD','HIST_RC','RES_RC'],
        cameraPosition: [198700, 1126,-8004252],
        lookAt: [198631, 1126,-8004260],
        description:`• </strong>Drilling: DD and RC Drilling.<br>`
      }
    },
    {
      type: 'scene',
      heading: 'Orebody',
      content: {
        visibleObjects: ['HIST_DD','HIST_RC','RES_RC','Orebody_HG'],
        cameraPosition: [198694, 1398,-8005275],
        lookAt: [198428, 1212,-8004894],
        description:`• </strong>Drilling: DD and RC Drilling.<br> • </strong> Giant Orebody: Giant Orebody<strong> `
      }
    },
    {
      type: 'scene',
      heading: 'Overview',
      content: {
        visibleObjects: ['UG_Workings','All_Shafts','Makorokoza','Orebody_HG'],
        cameraPosition: [198694, 1398,-8005275],
        lookAt: [198428, 1212,-8004894],
        description:`• </strong> Giant Orebody: Giant Orebody<strong> 
                    • </strong> All UG Workings: Historic <strong> 
                    • </strong> All Shafts: 3 meters<strong> 
                    • </strong> Makorkoza workings: The current makorokoza workings.<br>
                   `
      }
    },
    {
      type: 'scene',
      heading: 'Drone',
      content: {
        visibleObjects: ['Drone','UG_Workings','All_Shafts','Makorokoza','Orebody_HG'],
        cameraPosition: [198547, 1530,-8004261],
        lookAt: [198512, 1495,-8004261],
        description:`<strong>Drone</strong>: Depth: 100 meters<strong> 
                    • </strong> Giant Orebody: Giant Orebody<strong> 
                    • </strong> All UG Workings: Historic <strong> 
                    • </strong> All Shafts: 3 meters<strong> 
                    • </strong> Makorkoza workings: The current makorokoza workings.<br>
                   `
      }
    }
  ];

  const handleSlideClick = (index) => {
    setPreviousSlideWasScene(slides[currentSlide].type === 'scene');
    setCurrentSlide(index);
    setIsScribbleMode(false); // Disable scribble mode on page change
    
    setShowDescription(true)
    


    const newVisibility = { ...layerVisibility };
    
    // Reset all layers to invisible
    Object.keys(newVisibility).forEach((key) => {
      newVisibility[key].visible = false;
    });

    // Enable only the layers specified in the slide's visibleObjects
    const visibleObjects = slides[index]?.content?.visibleObjects || [];
    visibleObjects.forEach((name) => {
      if (newVisibility[name]) {
        newVisibility[name].visible = true;
      }
    });

    setLayerVisibility(newVisibility);
  };

  const handleToggleScribbleMode = () => {
    setIsScribbleMode(!isScribbleMode);
  };

  const renderSlideContent = () => {
    const slide = slides[currentSlide];
    switch (slide.type) {
      case 'image':
        return <img src={slide.content} alt="Slide" className="max-w-full max-h-full mx-auto overflow-hidden" />;

      case 'video':
        return (
          <video controls className="max-w-full max-h-full mx-auto">
            <source src={slide.content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case 'youtube':
        return (
          <iframe
            width="100%"
            height="100%"
            src={slide.content}
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="max-w-full max-h-full mx-auto"
          ></iframe>
        );

      case 'scene':
        return null; // We render the Canvas separately for scene slides

      default:
        return null;
    }
  };


  //////////LAYER VISIBILITY////////////////
  
  const [layerVisibility, setLayerVisibility] = useState({}); // Manage visibility of each layer
  const [showLayerControl, setShowLayerControl] = useState(false); // Toggle for Layer Control visibility
  
  useEffect(() => {
    const initialVisibility = {};
    ModelList.forEach((group) => {
      group.models.forEach((model) => {
        initialVisibility[model.name] = { visible: false, url: model.url };
      });
    });
    setLayerVisibility(initialVisibility);
  }, []);

   // Toggle visibility of layers from LayerControl
  const handleToggleVisibility = (modelName) => {
    setLayerVisibility((prevState) => ({
      ...prevState,
      [modelName]: {
        ...prevState[modelName],
        visible: !prevState[modelName].visible,
      }
    }));
  };

  return (
    <div className="flex h-screen relative">
      {/* Sidebar */}
      <div className={`transition-all duration-300 bg-blue-800 text-white p-1 h-full flex flex-col ${isSidebarOpen ? 'w-1/4 max-w-xs' : 'w-12 lg:w-18 md:w-16'}`}>
        <div className="relative hidden md:flex justify-center mb-4">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="bg-blue-600 text-white p-2 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
          >
            {isSidebarOpen ? '←' : '→'}
          </button>
        </div>
        {isSidebarOpen ? (
          <div  className=' h-screen overflow-y-auto p-5' >
            <h2 className="text-sm md:text-xl lg:text-2xl font-bold mb-4 text-center">GIANT</h2>
            <ul >
              {slides.map((slide, index) => (
                <li
                  key={index}
                  className={` flex items-center p-3 cursor-pointer ${index === currentSlide ? 'bg-blue-700' : 'bg-blue-600'} hover:bg-blue-700 transition-all rounded-lg mb-2`}
                  onClick={() => handleSlideClick(index)}
                >
                  <div className={`w-10 h-10  flex items-center justify-center rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400' : 'bg-blue-300'}`}>
                    {index + 1}
                  </div>
                  <span className="text-md font-bold ml-4">{slide.heading}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <ul className="flex flex-col items-center space-y-4 mt-12 overflow-y-auto h-screen" >
            {slides.map((_, index) => (
              <li
                key={index}
                className={`w-6 h-6 md:w-10 d:h-10 flex items-center justify-center cursor-pointer rounded-full shadow text-lg font-bold ${index === currentSlide ? 'bg-blue-400 text-white' : 'bg-blue-300 text-blue-900'}`}
                onClick={() => handleSlideClick(index)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Main Content */}
      <div className="flex-grow flex flex-col transition-all duration-300 relative">
        <div className="flex-grow flex items-center justify-center bg-gray-400 overflow-hidden">
          {renderSlideContent()}
        </div>
        {/* Description Box */}
        {showDescription && slides[currentSlide].content.description && (
          <div className="absolute bottom-14 left-0 w-full bg-gray-100 p-4 shadow-lg flex justify-between items-center text-gray-700 z-50">
            <p dangerouslySetInnerHTML={{ __html: slides[currentSlide].content.description }}></p>
            <button
              onClick={() => setShowDescription(false)}
              className="text-gray-500 hover:text-gray-700 font-bold text-sm w-fit"
            >
              &times;
            </button>
          </div>
        )}


        {slides[currentSlide].type === 'scene' && (
          <>

            {/* Toggle Button for Layer Control */}
             <button
              onClick={() => setShowLayerControl(!showLayerControl)}
              className = {`absolute top-16 right-4  ${showLayerControl?'bg-blue-500':'bg-gray-500'} text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-blue-600 z-50`}
              >
              🗂️
            </button>
           

            {/* Layer Control Panel */}
            {showLayerControl && (
              <LayerControl
                modelList={ModelList}
                layerVisibility={layerVisibility}
                onToggleVisibility={handleToggleVisibility}
              />
            )}

       
            <Canvas className="absolute inset-0" flat shadows gl={{ antialias: true }} camera={{ position: [0, 7000, 0], near: 0.5, far: 10000, fov: 35 }}>
              <SceneComponent
                currentSlide = {currentSlide}
                layerVisibility={layerVisibility} 
                cameraPosition={slides[currentSlide].content.cameraPosition}
                lookAt={slides[currentSlide].content.lookAt}
                animateCamera={previousSlideWasScene}
              />
            </Canvas>
            
            <Measurements/>
            <ColorChange/>
            <ObjectDetails/>
            <Info/>
          </>



        )}

        {/* Scribble Toggle Button */}
        <button
          onClick={handleToggleScribbleMode}
          className="absolute top-4 right-4 bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full shadow-lg hover:bg-gray-600 z-50"
        >
          ✏️
        </button>

        {/* Scribble Component */}
        <Scribble
          isScribbleMode={isScribbleMode}
          selectedColor={selectedColor}
          onColorChange={setSelectedColor}
        />

        

        {/* Footer Navigation */}
        <div className="py-1 bg-blue-200 text-blue-900 flex justify-between items-center px-4">
          <button
            onClick={() => handleSlideClick((currentSlide - 1 + slides.length) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 py-1 md:px-4 md:py-2 rounded shadow-md hover:bg-blue-700"
          >
            Previous
          </button>
          <span className="text-center text-sm md:text-lg w-fit">
            <div className="w-fit p-2 h-5  md:h-12 flex items-center justify-center rounded-full shadow bg-gray-100 font-bold">
              {currentSlide + 1} / {slides.length}
            </div>
          </span>
          <button
            onClick={() => handleSlideClick((currentSlide + 1) % slides.length)}
            className="bg-blue-600 text-white w-fit px-3 py-1 md:px-4 md:py-2 rounded shadow-md hover:bg-blue-700"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
