import React, { useRef, useEffect, useMemo, useState } from 'react';
import JSZip from 'jszip';
import * as d3 from 'd3';
import { Line } from '@react-three/drei';
import * as THREE from 'three';

export default function HolesLoader({ url, dataset, holetype,visible }) {
    const [holeData, setHoleData] = useState([]);
    const color = new THREE.Color();
    const instancesRef = useRef({});
    const constants_ = [0,0,0];

    useEffect(() => {
        const fetchData = async () => {
            const blob = await fetchDataWithProgress(url);
            console.log('Download complete. Extracting...');
            const zip = await JSZip.loadAsync(blob);
            const fileName = Object.keys(zip.files)[0]; // Assuming there's only one file in the zip
            const fileData = await zip.file(fileName).async('string');
            const parsedData = d3.csvParse(fileData);

            // Filter rows based on dataset and holetype, then order by Depth_From and holeID
            const filteredData = parsedData
                .filter(row => row.DataSet === dataset && row.Hole_Type === holetype)
                .sort((a, b) => a.Hole_ID.localeCompare(b.Hole_ID) || a.Depth_From - b.Depth_From);

            setHoleData(filteredData);
        };

        fetchData();
    }, [url, dataset, holetype]);

    const groupedData = useMemo(() => {
        const grouped = {};
        holeData.forEach(row => {
            const { Hole_ID } = row;

            if (!grouped[Hole_ID]) grouped[Hole_ID] = [];
            grouped[Hole_ID].push(row);
        });

        return grouped;
    }, [holeData]);

    const instances = useMemo(() => {
        const colorsArray = [];
        const posArray = [];
        const detArray = [];

        Object.keys(groupedData).forEach(holeID => {
            const holeRows = groupedData[holeID];
            holeRows.forEach(({ X, Y, Z, Depth_From, Depth_To, Au_ppm }) => {
                if (Au_ppm > 0.5) {
                    if (Au_ppm >= 5) {
                        color.set(0x800080); // PURPLE
                    } else if (Au_ppm >= 2.5) {
                        color.set(0xb45f06); // BROWN
                    } else if (Au_ppm >= 1.5) {
                        color.set(0xcc0000); // RED
                    } else if (Au_ppm >= 0.75) {
                        color.set(0x38761d); // GREEN
                    } else {
                        color.set(0xf1c232); // YELLOW
                    }
                    colorsArray.push([color.r, color.g, color.b]);
                    posArray.push([Number(X) - constants_[0], Number(Z) - constants_[2], constants_[1] - Number(Y)]);
                    detArray.push({ holeID, from: Depth_From, to: Depth_To, grade: Au_ppm });
                }
            });
        });

        return { colors: colorsArray, positions: posArray, details: detArray };
    }, [groupedData]);

    return (
        <>
            <group visible={visible}>
                {/* Rendering individual lines */}
                {Object.keys(groupedData).map(holeID => {
                    const holeData = groupedData[holeID];
                    const points = [
                        [Number(holeData[0].NAT_East) - constants_[0], Number(holeData[0].NAT_RL) - constants_[2], constants_[1] - Number(holeData[0].NAT_North)],
                        ...holeData.map(({ X, Y, Z }) => [Number(X) - constants_[0], Number(Z) - constants_[2], constants_[1] - Number(Y)])
                    ];

                    return (
                        <Line
                            key={holeID}
                            points={points}
                            closed={false}
                            curveType="centripetal"
                            userData={{ displayData: holeData[0] }}
                            lineWidth={holeData[0].Hole_Status === 'DRILLED' ? 3 : holeData[0].Hole_Status === 'PLANNED' ? 2 : 1}
                            color={holeData[0].Hole_Status === 'DRILLED' ? 'green' : holeData[0].Hole_Status === 'PLANNED' ? 'white' : 'black'}
                            name={holeID}
                        />
                    );
                })}

                <group>
                    {instances.positions.length > 0 && (
                        <Assays count={instances.positions.length} holes={instances} id={`${dataset}_${holetype}_Assay`} />
                    )}
                </group>
            </group>
        </>
    );
}

const fetchDataWithProgress = async (url) => {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get('Content-Length');

    let receivedLength = 0;
    const chunks = [];

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedLength += value.length;

        console.log(`Received ${((receivedLength / contentLength) * 100).toFixed(2)}% of ${contentLength} bytes`);
    }

    return new Blob(chunks);
};

function Assays({ count, holes, id }) {
    const ref = useRef();
    const [colors, setColors] = useState(new Float32Array());
    const [instancesData, setInstancesData] = useState([]);

    useEffect(() => {
        let colorsArray = [];
        let idata = [];
        for (let i = 0; i < count; i++) {
            const assayPosition = holes.positions[i];
            const color = holes.colors[i];
            const data = holes.details[i];

            idata.push(data);
            const assayMatrix = new THREE.Matrix4().makeTranslation(assayPosition[0], assayPosition[1], assayPosition[2]);
            ref.current.setMatrixAt(i, assayMatrix);
            colorsArray.push(color[0], color[1], color[2]);
        }
        setInstancesData(idata);
        setColors(Float32Array.from(colorsArray));
        ref.current.instanceMatrix.needsUpdate = true;
    }, [holes]);

    return (
        <instancedMesh  frustumCulled={false}  ref={ref} args={[null, null, count]} userData={{ instancesData }} name={id}>
            <sphereGeometry args={[0.5, 4, 4]}>
                <instancedBufferAttribute attach="attributes-color" args={[colors, 3]} />
            </sphereGeometry>
            <meshLambertMaterial vertexColors toneMapped={false} />
        </instancedMesh>
    );
}
